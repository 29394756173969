.default-form-cusica {
	label {
		font-family: OpenSans-Light;
		font-size: 1.2rem;
		/*text-transform: capitalize;*/
	}
	input,select,textarea {
		background: transparent;
		@include border-radius(0);
		border: solid 1px #fff;
		color: #fff;
		font-family: OpenSans-Light;
		font-size: 1.2rem;
		/*text-transform: capitalize;*/
	}
	span.checkbox-look {display: inline-block;
    			width: 1rem;
    			height: 1rem;
    			background: $grey-medium;}
	span.radio-look {
    			@extend span.checkbox-look;
    			@include border-radius(1rem);
    		}

	.radio,.checkbox {
		    margin-top: 10px;
    		margin-bottom: 10px;
    		input {
    			display: none;
    		}
    		
    		input:checked + span.radio-look {background: #fff}
    		input:checked + span.checkbox-look {background: #fff}
	}
	.forgot-password {
		font-family: OpenSans-Light;
		font-size: 1.4rem;
		color: $grey-medium;
	}
}
.input-search {
	position: relative;
	i {
		position: absolute;
		top: 0.5rem;
    	right: 1rem;
    	font-size: 2rem;
	}
}

.full-label {
	label {
		display: block;
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
}

.login-form-cusica {@extend .default-form-cusica; 
	input,select {
		background: #fff;color: $grey-darker
	}
	
}

#comentario {
	text-transform: none !important;
	font-weight: bold;
	font-size: large;
	-webkit-transition: background-color 0.4s ease-in-out;
	transition: background-color 0.4s ease-in-out;
}

#comentario:focus {
	background-color: #fff;
	color: $grey-darker;	
}