/**
 * cusica-player.css
 */

$player-primary: #EE485E;

.plyr__progress--played {
	color: $player-primary !important;
}

.plyr__progress--buffer {
    color: rgba(86, 93, 100, 0.75) !important;
    position: relative !important;
}

.plyr__tooltip {
	color: $player-primary !important;
}

.slider-volumen {
	float:left;
	height: 100px;
	width: 8px !important;
	position: absolute !important;
	top: -100px;
	margin:auto 18px;
	background-color: $player-primary !important;
}

.slider-volumen .noUi-connect {
	background: #FFFFFF !important;
}

.slider-volumen .noUi-handle {
	border-radius: 25px;
	width: 18px !important;
	height: 18px !important;

}

.slider-volumen .noUi-handle:before, .slider-volumen .noUi-handle:after {
	content: initial !important;
}

.plyr--full-ui input[type=range] {
    background: 0 0;
    border: 0;
    border-radius: 0;
    color: $player-primary;
    display: block;
    margin: 0;
    padding: 0;
    transition: box-shadow .3s ease;
    width: 100%;
    position: relative;
    top: -10px;
}

.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
    position: relative;
    background: 0 0;
    border: 0;
    border-radius: 3px;
    height: 6px;
    transition: all .3s ease;
    -webkit-user-select: none;
    user-select: none;
    background-image: linear-gradient(to right, currentColor var(--value), transparent var(--value));
}