.navbar-brand {
	img {max-height: 25px}
} 
.navbar-cusica li {
    margin: 0 0.5rem 0;
}
li.buscador {
	max-width: 500px !important;
}
li.top-bar-button{
	margin-top: 10px !important;
}
.side-menu {
	//background: black;
		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#303030+0,000000+40 */
background: #303030; /* Old browsers */
background: -moz-linear-gradient(top,  #303030 0%, #000000 40%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #303030 0%,#000000 40%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #303030 0%,#000000 40%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#303030', endColorstr='#000000',GradientType=0 ); /* IE6-9 */

	color: white;
	padding: 0 2rem 12rem 2rem;
	position: relative;
    overflow-y: auto;
    max-height: 100%;
    //width: 20% !important;
}

.item-group {
	padding: 2rem 0;
	border-bottom: solid 1px #353535;	
	&:last-child {margin-bottom: 2rem;}
	li {
		margin-top: 2rem;
		a {
		color: white;
		text-decoration: none;
		font-family: OpenSans-Regular;
		font-size: 1.2rem;
		text-transform: capitalize;
		&:hover {color: $grey-default;}
	}
	}
}
.info-user {
	.img-user {
	@include border-radius(50%);
	display: inline-block;
	max-width: 50%;
	}
}
.user-name {
	text-transform: capitalize;
	font-family: OpenSans-Semibold;
	font-size: 1.5rem;
	margin: 1rem 0 0.5rem 0;
}
.hours-of-music {
	font-family: OpenSans-Light;
	margin-bottom: 2.5rem;
}
.user-follow-info {
	li {
		display: inline-block;
		text-align: left;
	    padding: 0 0.5rem;
	    border-right: solid 1px;
	    margin-top: 0 !important;
	    &:last-child {border-right:none;}
	    .number {font-family: OpenSans-Bold;font-size: 1.2rem;}
	    .follow {font-family: Roboto-Light;font-size: 0.9rem;text-transform: uppercase;}
	}
}

.item-group-title {
	font-family: Roboto-Light;
	font-size: 1.3rem;
	text-transform: uppercase;
	color: $grey-default;
	margin-top: 0 !important;
}
.item-icon-left {
	i {
		font-size: 1.4rem;
		margin-right: 1rem;
	}
}

/*Player*/
.generic-player {
	position: fixed;
    bottom: 0;
    width: 100%;
    background: #000;
    z-index: 100;
    color: #fff;
    height: 7rem;
    float: left;
    .song-artist {
    	display: inline-block;
    	font-size: 1.5rem;
    	margin-left: 1.5rem;
    	span {font-family: Roboto-Regular;float: left;}
    	p {
    		font-family: Roboto-Regular;
    		font-size: 1.2rem;
    		color: $grey-medium;
    	}
    }
	}   
.artist-player {
	font-family: Roboto-Light;
				&::before {
					content: "";
					display: inline-block !important;
					width: 0.5rem;
					height: 0.5rem;
					background: #fff;
					@include border-radius(0.5rem);
					vertical-align: middle;
    				margin-right: 0.5rem;
   					margin-left: 0.5rem;
				}
}
.player-info {
	@include flexbox;
	@include align-items(center);
	position: absolute;
    left: 0;
}
.player-nav {
	position: absolute;
    right: 0;
    top: 0;
    padding-top: 0.8rem;
}
.player-progress {
	height: 0.5rem;
}
.progress-content {
	height: 0.5rem;
	background: $prim-pink;
	width: 80%;
}
.player-content {
	height: 6.5rem;
	position: relative;
    text-align: center;
}
.thumb-player {
	height: 6.5rem;
	vertical-align: top;
}

.player-actions {
	display: inline-block;
	width: 50%;
	font-size: 1.2rem;
	line-height: 6.5rem;
	span {vertical-align: middle;}
	.disabled {
	cursor: pointer;
	}
	.btn {
		font-size: 1.4rem;
		padding-left: 1.9rem;
    	padding-right: 1.9rem;
	}
}
/*//////////////*/