
/* ============================ */
/* VARIABLES                    */
/* ============================ */
  
$bar-width: 4px;
$bar-height: 20px;
$bar-color: #00B285;
$bar-radius: 2px;
    
/* ============================ */
/* SPINNER GENERAL              */
/* ============================ */

%bar {
  width: $bar-width;
  height: $bar-height;
  background-color: $bar-color;
  border-radius: $bar-radius;
}
.spinner {
  display: inline-block;
  position: relative;
  @extend %bar;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    @extend %bar;
    top: 0px;
  }
  &:before {
    left: -1 * ($bar-width + $bar-width/2);
  }
  &:after {
    left: $bar-width + $bar-width/2;
  }
}
/* ============================ */
/* SPINNER BOUNCE BOTTOM        */
/* ============================ */
  
@keyframes bounce-bottom {
  0% {
    height: 5px;
    margin-top: $bar-height - 5px;
  }
  50% {
    height: $bar-height;
    margin-top: 0px;
  }
  100% {
    height: 5px;
    margin-top: $bar-height - 5px;
  }
}
    
@mixin bounce-bottom($dur: 0.5s, $delay: 0s) {
  animation: bounce-bottom $dur ease $delay infinite;
}
    
.spinner-bounce-bottom {
  @include bounce-bottom(0.6s, 0.1s);
  &:before,
  &:after {
    top: auto;
    bottom: 0px;
  }
  &:before {
    @include bounce-bottom(0.6s, 0s);
  }
  &:after {
    @include bounce-bottom(0.6s, 0.2s);
  }
}
/* ============================ */
/* SPINNER BOUNCE TOP        */
/* ============================ */

@keyframes bounce-top
  0%
    height: 5px
    margin-bottom: $bar-height - 5px
  50%
    height: $bar-height
    margin-bottom: 0px
  100%
    height: 5px
    margin-bottom: $bar-height - 5px
    
@mixin bounce-top($dur: 0.5s, $delay: 0s)
  animation: bounce-top $dur ease $delay infinite
    
.spinner-bounce-top
  @include bounce-top(0.6s, 0.1s)
  &:before
    @include bounce-top(0.6s, 0s)
  &:after
    @include bounce-top(0.6s, 0.2s)  

/* ============================ */
/* SPINNER BOUNCE MIDDLE        */
/* ============================ */

@keyframes bounce-middle
  0%
    height: 4px
    margin-top: $bar-height / 2 - 2px
    margin-bottom: $bar-height / 2 - 2px
  50%
    height: $bar-height
    margin-top: 0px
    margin-bottom: 0px
  100%
    height: 4px
    margin-top: $bar-height / 2 - 2px
    margin-bottom: $bar-height / 2 - 2px
    
@mixin bounce-middle($dur: 0.5s, $delay: 0s)
  animation: bounce-middle $dur ease $delay infinite
    
.spinner-bounce-middle
  @include bounce-middle(0.6s, 0.1s)
  &:before,
  &:after
    top: 50%
    transform: translateY(-1 * $bar-height / 2) translateZ(0)
  &:before
    @include bounce-middle(0.6s, 0s)
  &:after
    @include bounce-middle(0.6s, 0.2s)  
    
/* ============================ */
/* SPINNER BLINK                */
/* ============================ */

@keyframes glow
  0%
    background-color: transparent
  50%
    background-color: $bar-color
  100%
    background-color: transparent

@mixin glow($dur: 0.5s, $delay: 0s)
  animation: glow $dur $delay infinite//
    
.spinner-blink
  @include glow(0.6s, 0.1s)
  &:before
    @include glow(0.6s, 0s)
  &:after
    @include glow(0.6s, 0.2s)