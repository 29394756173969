//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// koel
@import "partials/_vars.scss";
@import "partials/_mixins.scss";
@import "partials/_hacks.scss";

// Here we set some default variables to tweak plyr to our theme.
$plyr-blue: $colorHighlight;
$plyr-control-color: $colorHighlight;
$plyr-control-bg-hover: $colorHighlight;
$plyr-volume-track-height: 6px;
$plyr-control-spacing: 6px;
@import "vendor/_plyr.scss";

@import "vendor/_nprogress.scss";

@import "partials/_shared.scss";


@import "playlist.scss";
@import "scroll.scss";
@import "index.scss";
@import "mixtape.scss";
@import "fileinput.scss";
@import "spinners.scss";