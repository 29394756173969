$colorMainBgr: #181818;
$color2ndBgr: #282828;
$colorMainText: #fff;
$color2ndText: #a0a0a0;
$colorBtnBgr: #0191f7;
$colorBtnText: #fff;
$colorDirtyInputBgr: #fff5b0;

$colorHeart: #bf2043;
$colorGreen: #56a052;
$colorBlue: #4c769a;
$colorRed: #c34848;
$colorOrange: #ff7d2e;
$colorGrey: #3c3c3c;

$colorSidebarBgr: #212121;
$colorExtraBgr: #212121;
$colorSearchFormBgr: transparent;
$colorPlayerControlsBgr: transparent;

$colorLink: #aaa;
$colorLinkHovered: #fff;

$colorHighlight: $colorOrange;

$fontFamily: 'Roboto', sans-serif;
$fontSize: 13px;

$fontWeight_UltraThin: 100;
$fontWeight_Thin: 300;
$fontWeight_Normal: 500;

$headerHeight: 48px;
$footerHeight: 64px;
$mainHeadingHeight: 64px;

$extraPanelWidth: 334px;
