/* Mixtape */

#arrastra-placeholder {
	padding: 3rem;
}

.ver-album:hover {
	cursor: pointer;
}

.song-list-item {
	cursor: move;
	z-index: 999;
}

#song-list > .song-list-item > .drop-hide {
	display: block;
}

#drop-songs{
	.row {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.song-list-item {
		font-size: 11px !important;
		color: #fff !important;
		width: 100% !important;
		margin-bottom: 1rem !important;
		padding: 15px 0 !important;
		border: 1px solid #fff !important;
		.drop-hide {
			display: none !important;
		}
		.song-list-item-title {
			text-align: left;
			top: 5px;
			.drop-hide {
				display: none !important;
			}
		}
	}
}

#drop-songs > .song-list-item > .col-md-1 > .delete-from-mixtape {
	visibility: visible !important;
}