/**
 * main.scss
 */

@import "_bootstrap";

@import "partials/reset";
@import "font-awesome";
@import "partials/mixins";
@import "partials/fonts";
@import "partials/variables";
@import "partials/buttons";
@import "partials/layout";
@import "partials/modules";
@import "partials/forms";



html {font-size: 10px}
body {font-family:OpenSans-Regular;font-size: 1rem;color: #fff;}
/*General Clases*/
.generic-container {margin-top: 5rem;background: $generic-bg;color: #fff;}
.login-container {@extend .generic-container; margin-top: 0 !important;padding-top: 12rem;padding-bottom: 12rem;}
.img-responsive {max-width: 100%}
.col-md-fifth {@extend .col-md-5;width: 20%;margin: 20px auto;}
.flex {
	@include flexbox;
	.full-block {
		@include flex(1,100%,null);
		margin-top: 3rem;
	}
}
.justify-content {
	@include justify-content(space-between);
}
.justify-content-center {
	@include justify-content(center);
}
.aling-center-vertical {
	@include align-items(center);
}
.all-icon-left {
	i {float: left;}
}
.all-icon-right {
	i {float: right;}
}
.mod-border-left {
	border-left: solid 1px $grey-dark;
}
/*/////////////*/

/*Banner Generic*/
.generic-banner {
	background: $prim-pink;
	height: 26.5rem;
	background-size: cover; 
	@include flexbox;
	@include align-items(center);
	@include justify-content(space-between);
}
.costumize {
	width: 30%;
	margin-left: 3rem;
}
.cusicalive {
	margin-bottom: 1.5rem;
}
.currently-listening {
	.you-listen {
		font-family: OpenSans-Light;
		font-size: 2.4rem;
		text-transform: uppercase;
		margin-right: 3rem;
	}
}
.play-and-info {
	font-size: 2.5rem;
	button {
		font-size: 7rem;
		display: inline-block;
		vertical-align: baseline;
		margin-top: 1.5rem;
		margin-right: 1.5rem;
	}
	.song-info {
		display: inline-block;
	}
	.song-name {
		font-family: Roboto-Light;
		text-transform: uppercase;
	}
	.artist-name {
		font-family: Roboto-Light;
	}
}
.btn-separator {
	margin-left: 1.5rem;
}

.no-padding-top {padding-top: 0 !important}
.no-padding-right {padding-right: 0 !important}
.no-padding-bottom {padding-bottom: 0 !important}
.no-padding-left {padding-left: 0 !important}
.no-margin-top {margin-top: 0 !important}
.no-margin-right {margin-right: 0 !important}
.no-margin-bottom {margin-bottom: 0 !important}
.no-margin-left {margin-left: 0 !important}
.no-border {border-color: transparent;}
/*////////////////*/



/*wide-inner-banner*/
.wide-inner-banner {
	background: $grey-dark;
	text-transform: uppercase;
	font-size: 2rem;
	text-align: center;
	height: 9rem;
	line-height: 9rem;
}
/*/////////////////*/

/*Generes Moods*/
.generes-moods {
	.row {margin-bottom: 3rem !important;}
}
/*/////////////*/

.liked {
	color: #FF0000 !important;
}

.thumb-usuario {
	border: 0px transparent !important;
}