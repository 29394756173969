/* Playlist */
.meta-data-info {
  display: none; }

.img-center {
  display: block;
  margin: 0 auto; }

.reclenty-played-song {
  cursor: move; }

.content-sound {
  border: 1px dotted white;
  min-height: 80px;
  height: auto;
  clear: both; }

.content_image_id3_update {
  width: 252px;
  height: 252px;
  display: block;
  background-color: #29ABE2;
  cursor: pointer;
  margin: 0 auto;
  border: 1px dotted white; }

.archivo {
  display: none !important; }

.add-playlist-thumb, .add-banner-playlist {
  cursor: pointer; }

.add-playlist-thumb:hover, .add-banner-playlist:hover {
  border: 1px dotted white; }

.content-playlist {
  margin-top: -25px; }

.all-info-sound {
  display: inline-block;
  width: 92%;
  /*height: 62px;*/
  padding-left: 15px;
}

.arte-sound {
  width: 13%;
  padding-right: 10px;
  padding-left: 10px; }

.info-sound{
    width: 85%;
}

.reclenty-played-song{
   padding-top: 9px !important;
   padding-bottom: 9px !important;
   padding-right: 5px;
   margin-right: 18px; 
}

.reclenty-played-song:hover{
    background-color: #525252;
}

.info-sound p {
  display: block;
  width: auto; }

.info-sound p:first-child {
  font-size: 14px;
  color: #29ABE2; }

.btn-sound {
  width: 5%; }

.btn-sound button {
  background-color: transparent !important;
  float: right;
  width: 100%; }

.publicidad-container {
  height: 100vh;
}
.img-publicidad-panel {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}