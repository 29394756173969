*, *:before, *:after {
    box-sizing: border-box;
    outline: none;
}

body, html {
    overflow: auto;
}

input, select, button, textarea, .btn {
    appearance: none;
    border: 0;
    outline: 0;
    font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: $fontWeight_Thin;
    padding: 4px 8px;
    border-radius: 3px;

    &:required, &:invalid {
        box-shadow: none;
    }

    &.dirty {
        background: $colorDirtyInputBgr;
    }

    &[type="search"] {
        border-radius: 12px;
        height: 24px;
        padding: 0 6px;
    }

    &[type="text"] {
        display: block;
    }

    &:focus { outline: none !important; }
    &::-moz-focus-inner { border: 0 !important; }
}

input[type="checkbox"] {
    border: 1px solid rgba(255, 255, 255, .3);
    border-radius: 0;
    background: transparent;
    color: #555;
    clear: none;
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    height: 16px;
    margin: -4px 4px 0 0;
    outline: 0;
    padding: 0!important;
    text-align: center;
    vertical-align: middle;
    width: 16px;
    min-width: 16px;
    box-shadow: inset 0 1px 2px rgba(0,0,0,.1);

    &:checked{
        background: #fff;

        &:before {
            font-family: FontAwesome;
            content: "\f00c";
            color: $colorHighlight;
            display: inline-block;
            vertical-align: middle;
            width: 14px;
            height: 14px;
            line-height: 14px;
            text-align: center;
        }
    }
}

a, a:link, a:visited {
    /*color: $colorLink;*/
    text-decoration: none;
    cursor: pointer;
}

.clear, .clearfix {
    &::after {
        content: " ";
        clear: both;
        display: block;
    }
}

.side {
    width: 256px;
}

.ir {
    color: transparent;
    font: 0/0 serif;
}

.control {
    cursor: pointer;
    color: $colorLink;

    &:hover {
        color: $colorLinkHovered;
    }
}

p {
    line-height: 20px;
}

.help {
    opacity: .7;
    font-size: 90%;
    line-height: 16px;
}

label {
    font-size: 14px;
    margin-bottom: 8px;
    display: block;
}

button, input[type="submit"], input[type="reset"], input[type="button"], .btn {
    background: $colorBtnBgr;
    color: $colorBtnText;
    font-size: 14px;
    padding: 8px 12px;
    cursor: pointer;

    @include inset-when-pressed();

    &.btn-green {
        background-color: $colorGreen;

        &:hover {
            background-color: darken($colorGreen, 10%);
        }
    }

    &.btn-white {
        background-color: #fff;
        color: #333;

        &:hover {
            background-color: darken(#fff, 10%);
        }
    }

    &.btn-red {
        background-color: $colorRed;

        &:hover {
            background-color: darken($colorRed, 10%);
        }
    }

    &.btn-blue {
        background-color: $colorBlue;

        &:hover {
            background-color: darken($colorBlue, 10%);
        }
    }

    &.btn-grey {
        background-color: $colorGrey;

        &:hover {
            background-color: darken($colorGrey, 10%);
        }
    }

    &.btn-orange {
        background-color: $colorOrange;

        &:hover {
            background-color: darken($colorOrange, 10%);
        }
    }
}

.tabs {
    .header {
        $tabColor: #5c5c5c;
        border-bottom: 1px solid $tabColor;

        a {
            padding: 8px 12px;
            margin-left: 4px;
            border-radius: 4px 4px 0 0;
            text-transform: uppercase;
            color: lighten($tabColor, 50%);
            opacity: .4;
            border: 1px solid $tabColor;
            margin-bottom: -1px;
            float: left;

            &.active {
                border-bottom: 1px solid $colorExtraBgr;
                opacity: 1;
            }
        }
    }

    .panes {
        padding: 16px 0;
    }

    &.tabs-white {
        .header {
            border-bottom-color: #aaa;

            a {
                color: #333;
                border-color: #aaa;

                &.active {
                    border-bottom-color: #fff;
                }
            }
        }
    }
}

.form-row {
    margin-top: 16px;
    position: relative;
}
