.btn {
	@include border-radius(0px);
	text-transform: uppercase;
}
/*Button Types*/
.btn-full {
	width: 100%
}
.btn-outline {
	border: solid 1px;
	background: transparent !important;
}
.btn-clear {
	border-color: transparent !important;
    background: none !important;
    box-shadow: none !important;
    border: none !important;	
}
.btn-icon {
	@extend .btn-clear;
	padding: 0;
}
.btn-icon-right {
	i {margin-left: 0.5rem}
}
.btn-icon-left {
	i {margin-right: 0.5rem}
}
/*Button Sizes*/
.btn-sm {font-size: 1rem;}
.btn-lg {font-size: 2rem !important;}

/**Button Styles*/
.btn-white {
	background: white;
	color: #000;
}
.btn-outline.btn-white {
	border-color: white !important;
	color: #fff;
}
.btn-clear.btn-white {
	color: #fff;
}
.btn-pink {
	background: $prim-pink;
	color: #fff;
	&:hover {background: $pink-dark;color: #fff !important}
}
.btn-blue {
	background: $prim-blue;
	color: #fff;
}
.btn-fb {
	background: #3B5998;
	color: #fff;
}
.btn-google-plus {
	background: #D34836;
	color: #fff;
}
.btn-outline.btn-pink {
	border-color: $prim-pink !important;
	color: $prim-pink;
}
.btn-clear.btn-pink {
	color: $prim-pink;
}
.btn-clear.btn-blue {
	color: $prim-blue;
}
.btn-grey {
	background: $grey-medium;
	color: #fff;
	&:hover {background: $grey-dark;color: #fff !important}
}
.btn-outline.btn-grey {
	border-color: $grey-medium !important;
	color: $grey-medium;
}
.btn-clear.btn-grey {
	color: $grey-medium;
}

.navbar-btn {@extend .btn-white;}
.navbar-icon {
	font-size: 2rem;
	@extend .btn-clear;
	&:hover {color: $grey-default;}
}

/* Boton Like */
.boton-like {
	
}
.boton-like:hover {
	background-color: #f26450 !important;
	border-color: #f26450 !important;
	color: #fff;
}

/* buscador */
.twitter-typeahead {
	margin-right: 1px !important;
}
.twitter-typeahead:focus {
	margin: inherit !important;
}
.boton-buscador-cusica {
	margin-top:-5px;
	padding: 3px 4px 4px 5px !important;
	height: 30px !important;
	border-radius: 20px !important;
	border: none !important
}
.boton-buscador-cusica:hover {
	color: black !important;
	background: white !important;
	transition: width 0.6s, border-radius 0.6s, background 0.6s, box-shadow 0.6s;
}
.boton-buscador-active {
	background-color: #fff !important;
	color: #222 !important;
	border-top-right-radius: 0 !important;
	border-top-left-radius: 12px !important;
	border-bottom-right-radius: 0 !important;
	border-bottom-left-radius: 12px !important;
	margin-right: -2.6px;
	margin-top: -2px;
	transition: width 0.1s, border-radius 0.1s, background 0.1s, box-shadow 0.1s;
}
.buscador-cusica {
	width: 1px !important;
	height: 30px !important;
	margin-top: 14px !important;
	padding: 0 !important;
	font-size: large;
	background-color: #222;
	border-radius: 0px 12px 12px 0px;
	transition: width 0.6s cubic-bezier(0,1.22,.66,1.39), border-radius 0.6s, background 0.6s;
}

.buscador-cusica:focus {
	width: 300px !important;
	background-color: #FFF !important;
	padding-left: 12px !important;
	margin-right: -5px !important;
}

a.resultado-item {
	width: 320px !important;
	background-color: #fff !important;
	color: #222 !important;
	border: 0px transparent;
}

a.resultado-item:hover {
	background-color: #DCDCDC !important;
	cursor: pointer;
}

a.resultado-item > img.resultado-usuario {
	border-radius: 50%;
	background-clip: padding-box;
	display: inline-block;
	max-width: 50%;
}

a.ver-mas {
	width: 320px !important;
	background-image: linear-gradient(#f26450, #ed4b63) !important;
	background-color: #f26450;
	border: 0px transparent;
}

a.ver-mas:hover {
	background-image: linear-gradient(#f26450, #ed4b63) !important;
	background-color: #f26450 !important;
	cursor: pointer;
}

.tt-menu {
	margin-top: 15px !important;
	background-color: #fff !important;
	color: #222 !important;
	width: 320px !important;
}

ul.tt-selectable > li {
	margin: 0px;
	width: 100%;
}
