@include font-face(Roboto-ThinItalic, fonts/Roboto-ThinItalic);
@include font-face(Roboto-Thin, fonts/Roboto-Thin);
@include font-face(Roboto-Regular, fonts/Roboto-Regular);
@include font-face(Roboto-MediumItalic, fonts/Roboto-MediumItalic);
@include font-face(Roboto-Medium, fonts/Roboto-Medium);
@include font-face(Roboto-LightItalic, fonts/Roboto-LightItalic);
@include font-face(Roboto-Light, fonts/Roboto-Light);
@include font-face(Roboto-Italic, fonts/Roboto-Italic);
@include font-face(Roboto-BoldItalic, fonts/Roboto-BoldItalic);
@include font-face(Roboto-Bold, fonts/Roboto-Bold);
@include font-face(Roboto-BlackItalic, fonts/Roboto-BlackItalic);
@include font-face(Roboto-Black, fonts/Roboto-Black);

@include font-face(OpenSans-SemiboldItalic, fonts/OpenSans-SemiboldItalic);
@include font-face(OpenSans-Semibold, fonts/OpenSans-Semibold);
@include font-face(OpenSans-Regular, fonts/OpenSans-Regular);
@include font-face(OpenSans-LightItalic, fonts/OpenSans-LightItalic);
@include font-face(OpenSans-Light, fonts/OpenSans-Light);
@include font-face(OpenSans-Italic, fonts/OpenSans-Italic);
@include font-face(OpenSans-ExtraBoldItalic, fonts/OpenSans-ExtraBoldItalic);
@include font-face(OpenSans-ExtraBold, fonts/OpenSans-ExtraBold);
@include font-face(OpenSans-BoldItalic, fonts/OpenSans-BoldItalic);
@include font-face(OpenSans-Bold, fonts/OpenSans-Bold);