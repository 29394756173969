.img-container{
	overflow:hidden;
}
.banner-publicidad {
	display: block;
	width: 100% !important;
	min-height: 100% !important;
	margin: auto;
}
#stalkingModal {
	max-width: 600px;
	margin: auto
}
.stalking-avatar {
	border-radius: 50%;
}

header {
	margin-bottom: 6em;
}