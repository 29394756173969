// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot  ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote("../" + $path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

// Single side border-radius

@mixin border-top-radius($radius) {
  -webkit-border-top-right-radius: $radius;
  border-top-right-radius: $radius;
   -webkit-border-top-left-radius: $radius;
   border-top-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-right-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
     -webkit-border-top-right-radius: $radius;
     border-top-right-radius: $radius;
     background-clip: padding-box;
}
@mixin border-bottom-radius($radius) {
  -webkit-border-bottom-right-radius: $radius;
  border-bottom-right-radius: $radius;
   -webkit-border-bottom-left-radius: $radius;
   border-bottom-left-radius: $radius;
   background-clip: padding-box;
}
@mixin border-left-radius($radius) {
  -webkit-border-bottom-left-radius: $radius;
  border-bottom-left-radius: $radius;
     -webkit-border-top-left-radius: $radius;
     border-top-left-radius: $radius;
     background-clip: padding-box;
}



/*Gradiente Vertical*/
@mixin gradient-vertical($start-color, $end-color, $start-percent, $end-percent) {
  background-image: -webkit-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Safari 5.1-6, Chrome 10+
  background-image: -o-linear-gradient(top, $start-color $start-percent, $end-color $end-percent);  // Opera 12
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10, Firefox 16+, Opera 12.10+, Safari 7+, Chrome 26+
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin flexbox {
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flexbox;
	display: flex;
}

@mixin align-items($value) {
	@if $value == flex-start {
		-webkit-box-align: start;
		-ms-flex-align: start;
	} @else if $value == flex-end {
		-webkit-box-align: end;
		-ms-flex-align: end;
	} @else {
		-webkit-box-align: $value;
		-ms-flex-align: $value;
	}
	-webkit-align-items: $value;
	-moz-align-items: $value;
	align-items: $value;
}


@mixin justify-content($value) {
	@if $value == flex-start {
		-webkit-box-pack: start;
		-ms-flex-pack: start;
	} @else if $value == flex-end {
		-webkit-box-pack: end;
		-ms-flex-pack: end;
	} @else if $value == space-between {
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
	} @else if $value == space-around {
		-ms-flex-pack: distribute;		
	} @else {
		-webkit-box-pack: $value;
		-ms-flex-pack: $value;
	}
	-webkit-justify-content: $value;
	-moz-justify-content: $value;
	justify-content: $value;
}

@mixin flex-wrap($value) {
	// No Webkit Box fallback.
	-webkit-flex-wrap: $value;
	-moz-flex-wrap: $value;
	@if $value == nowrap {
		-ms-flex-wrap: none;
	} @else { 
		-ms-flex-wrap: $value; 
	}
	flex-wrap: $value;
}

@mixin flex($fg, $fs, $fb) {
    
	// Set a variable to be used by box-flex properties
	$fg-boxflex: $fg;

	// Box-Flex only supports a flex-grow value so let's grab the
	// first item in the list and just return that.
	@if type-of($fg) == 'list' {
		$fg-boxflex: nth($fg, 1);
	}

	-webkit-box-flex: $fg-boxflex;
	-webkit-flex: $fg $fs $fb;
	-moz-box-flex: $fg-boxflex;
	-moz-flex: $fg $fs $fb;
	-ms-flex: $fg $fs $fb;
	flex: $fg $fs $fb;
}

@mixin flex-flow($values) {
	// No Webkit Box fallback.
	-webkit-flex-flow: $values;
	-moz-flex-flow: $values;
	-ms-flex-flow: $values;
	flex-flow: $values;
}


// =============================================================================
// koel
// =============================================================================

@mixin vertical-center() {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin artist-album-wrapper() {
    justify-content: space-between;
    flex-wrap: wrap;
    display: flex;
    align-content: flex-start;

    .item {
        align-self: flex-start;
    }
}

@mixin artist-album-card() {
    .item {
        flex-direction: column;
        margin-bottom: 16px;
        width: 23.5%;
        position: relative;

        .as-list & {
            flex-direction: row;
            display: flex;
            width: 100% !important;

            .cover {
                flex: 0 0 80px;
                height: 80px;
                padding-top: 0;
            }

            footer {
                flex: 1;
            }
        }

        .as-list &.filler {
            display: none;
        }

        @media only screen and (max-width: 1200px) {
            width: 32%;
        }

        @media only screen and (max-width: 960px) {
            width: 48%;
        }

        @media only screen and (max-width: 320px) {
            width: 100%;
        }

        html.with-extra-panel & {
            width: 32%;

            @media only screen and (max-width: 1200px) {
                width: 48%;
            }

            @media only screen and (max-width: 960px) {
                width: 100%;
            }
        }

        .cover {
            @include vertical_center();

            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            width: 100%;
            padding-top: 100%;
            position: relative;

            @mixin control($playBtnWidth, $fontSize, $textIndent) {
                width: $playBtnWidth;
                height: $playBtnWidth;
                line-height: $playBtnWidth;
                font-size: $fontSize;
                text-indent: $textIndent;
                left: calc(50% - #{$playBtnWidth/2});
            }

            .control {
                .as-list & {
                    @include control(46px, 27px, 2px);
                }

                @include control(96px, 54px, 5px);
                display: none;
                text-align: center;
                background: #111;
                border-radius: 50%;
                opacity: .7;
                border: 1px solid transparent;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                transition: .3s;

                &:hover {
                    opacity: 1;
                    border-color: #fff;
                    transform: translateY(-50%) scale(1.1);
                }

                html.touchevents & {
                    display: block;
                }
            }

            &:before {
                content: " ";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                transition: .3s;
            }

            &:hover {
                &:before {
                    background-color: rgba(0, 0, 0, .4);
                }

                .control {
                    display: block;
                }
            }
        }

        footer {
            padding: 16px;
            background: #333;
            flex: 1;
        }

        .name {
            font-weight: 500;
        }

        .meta {
            color: $color2ndText;
            margin-top: 4px;
            font-size: 12px;
        }

        a.name, a.artist {
            display: block;
            color: $colorMainText;

            &:hover {
                color: $colorHighlight;
            }

            .as-list & {
                display: inline;
            }
        }
    }
}

@mixin inset-when-pressed() {
    &:active {
        box-shadow: inset 0px 10px 10px -10px rgba(0,0,0,1);
    }
}

@mixin button-group() {
    display: flex;
    position: relative;
    min-width: 192px;
    justify-content: flex-end;

    button {
        $buttonHeight: 28px;

        font-size: 12px;
        height: $buttonHeight;
        padding: 0 16px;
        line-height: $buttonHeight;
        text-transform: uppercase;
        display: inline-block;

        border-radius: $buttonHeight/2 0px 0px $buttonHeight/2;

        &:last-of-type {
            border-top-right-radius: $buttonHeight/2;
            border-bottom-right-radius: $buttonHeight/2;
        }

        &:not(:first-child) {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 1px;
        }

        i {
            margin-right: 4px;
        }
    }
}

@mixin context-menu() {
    font-weight: $fontWeight_Thin;
    font-size: $fontSize;
    padding: 4px 0;
    min-width: 144px;
    color: #111;
    background-color: rgb(232, 232, 232);
    position: fixed;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    z-index: 1001;
    align-items: stretch;
    text-align: left;
    box-shadow: inset 0 0px 0 rgba(255,255,255,.6), 0 22px 70px 4px rgba(0,0,0,0.56), 0 0 0 1px rgba(0, 0, 0, 0.3);

    input[type="search"], input[type="text"], input[type="email"], input[type="url"] {
        background: #fff;

        &:focus {
            background: $colorDirtyInputBgr;
        }
    }
}
