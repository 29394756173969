/*Module Double Generic*/
.mod-title {
	padding: 3rem 0 2.2rem 0;
	font-weight: 300;
	text-transform: uppercase;
	border-bottom: solid 1px $grey-dark;
	color: #fff;
	max-width: 98% !important;
	display: block;
	margin: auto;
	.btn, input {
		margin-top: -0.5rem;
	}
	input {
		height: 3.2rem;
	}
	form {
		margin-right: 1.5rem;
	}
}
.mod-subtitle {
	@extend .mod-title;
	text-transform: capitalize;
}
.mod-gen-content {
	padding: 2.4rem 4rem;
	.row {
		&:first-child {margin-bottom: 4rem;}
		&:last-child {margin-bottom: 2rem;}
	}
}
.item-mod {
	.img-container {
		width: 100%;
		height: 17.8rem;
		/*background: $prim-blue;*/
	}
	.name-playlist {
		font-family: OpenSans-Regular;
		font-size: 1.5rem;
		font-weight: bolder;
		padding: 0 0 1rem 0;
		border-bottom: solid 1px #fff;
		text-transform: capitalize;
		small {
			font-weight: 100;
			a {
				color: $grey-dark;
			}
		}
	}
	
	
}
.tags {
		a {
			color: $gray-lighter;
			text-decoration: none;
			margin-right: 0.7rem;
			font-style: italic;
			&:last-child {
				margin-right: 0;
			}
		}
	}
.playlist-info {
		padding: 0.7rem 0;
		font-family: Roboto-Regular;
		font-size: 1.3rem;
		text-transform: none;
		li {
			display: inline-block;
			vertical-align: top;
			margin-right: 0.7rem;
			&:last-child {
				margin-right: 0;
			}
		}
		i {color: $prim-pink;margin-right: 0.3rem;}
	}
/*/////////////////*/

/*Module Mixtape*/
.mod-mixtape-content {
	padding: 4rem 2.4rem;	
}
.images-mixtape {
	img {
		width: 50%;
		float: left;
	}
}

.mixtape-info {
	@include align-items(stretch);
	.mod-title {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}
}
.mixtape-info > span {
	font-family: OpenSans-Light;
	font-size: 12px;
	text-transform: capitalize;
	position: relative;
}
.mixtape-description {
	font-size: 1.2rem;
	margin-top: 1rem;
}
.mixtape-options {
	position: absolute;
	bottom: 0; 
}
/*///////////////*/

/*Module Song List*/
.song-list-content {
	padding: 0 2.4rem;
}
.songs-wrapper {
	padding: 1rem 0;
	border-top: solid 1px $grey-dark;
}
.song-list-titles {
	font-family: OpenSans-Semibold;
	font-size: 1.4rem;
	text-transform: uppercase;
	margin-bottom: 5rem;
}
.song-list-item {
	font-size: 1.4rem;
	text-transform: capitalize;
	margin-bottom: 5rem;
	&:hover {
		.delete-from-mixtape {visibility: visible;}
		.song-thumb {
			.btn {
				visibility: visible;
			}
		}
	}
}
.song-number {
	width: 4rem;
	display: inline-block;
	font-size: 1.8rem;
	color: $grey-dark;
}
.delete-from-mixtape {
	font-size: 1.8rem;
	visibility: hidden;
	cursor: pointer;
}
.song-thumb {
    display: inline-block;
    width: 3rem;
    vertical-align: middle;
    margin-right: 3rem;
    position: relative;
    	img {width: 100%}
    	.btn {
    		position: absolute;
    		top: 20%;
    		left: 25%;
    		visibility: hidden;
    	}
	}
/*///////////////*/

/*My Songs*/
.my-songs {
	.name {font-size: 1.4rem;font-family: OpenSans-Semibold;text-transform: capitalize;line-height: 1.5;}
	.artist {@extend .name;font-family: OpenSans-Light;}
}
.display-album-info {
	.album-info {
		font-family: OpenSans-Light;
		font-size: 2.4rem;
		text-transform: capitalize;
		.duration {
			font-size: 1.2rem;
			font-family: OpenSans-Bold;
			text-transform: capitalize;
			margin-right: 2.5rem;
			margin-top: 0.6rem;
		}
	}
}
.artist-action {
	margin-top: 1rem;
	.album-artist {
		@extend .album-info;
		font-size: 1.8rem !important;
		display: inline-block;
		max-width: 50%;
		color: $grey-medium;
	}
}
/*///////////////*/

/*Cusicalive*/

.add-artist {
	margin-top: 7.5rem;
	@include flex-flow(row wrap);
}
.artist-select-wrapper {
    min-height: 50rem;
}
.selected-artist {
	font-size: 1.1rem;
	font-family: OpenSans-Regular;
	color: $grey-default;
	text-transform: capitalize;
	margin-top: 1rem;
	li {
		padding: 1rem 0;
		i {
			color: #fff;
			cursor: pointer;
		}
	}
}
.cusicalive-description {
	font-style: 1.2rem;
	line-height: 1.4;
}
/*///////////////*/

/*Asides*/
.aside-title {
	font-size: 2rem;
	text-transform: capitalize;
}
/*////////////////*/

/**/
.filter-separator {
	margin-right: 8rem;
}
/*////////////////*/

/*Playlist Detail*/
.playlist-detail-wrapper {
	background-size: cover;
}
/* Fondo */
.playlist-detail-wallpaper {
	position: absolute !important;
    left: 0;
    right: 0;
    z-index: -999;
    display:block;
    filter: blur(5px);
    height: 100%;
}
.playlist-detail-wallpaper > .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: rgba(0,0,0,0.8); /*dim the background*/
}

.playlist-detail-header{
    position: relative;
    left: 0;
    right: 0;
}
.playlist-date {
	@extend .playlist-info;
	margin-right: 2rem;	
}
.mod-playlist-detail-content {@extend .mod-mixtape-content}
.playlist-options {
	@extend .mixtape-options;
	width: 96%;
}
.playlist-tags {
		@extend .tags;
	font-size: 1rem;
	margin: 2rem 0 1rem 0;
	i {
		font-size: 1.4rem;
	}
}
.created-by {
	font-family: OpenSans-LightItalic;
}
.creator-thumb {
	max-width: 2rem;
	@include border-radius(1rem);
	margin: 1rem 1rem 0 0;
}
.creator-name {
	font-family: OpenSans-Semibold;
	font-size: 1.2rem;
	margin-top: 1.5rem;
	text-transform: capitalize;
	display: inline-block;
}
.pl-recently-played {margin-bottom: 5rem;}
.reclenty-played-song {
	padding: 2.5rem 0;
	border-bottom: solid 1px $grey-darker;
	* {
		    display: inline-block;
    vertical-align: middle;
	}
	.song-thumb {max-width: 3rem;margin-right: 1.5rem;}
	.btn {margin-top: 1rem;}
	.song-artist {
		display: block;
		font-size: 1.4rem;
		text-transform: capitalize;
	}
	.album-date {
		@extend .song-artist;
		font-size: 1.2rem;
		margin-top: 0.3rem;
	}
}
.track-number {
	font-family: OpenSans-Light;
	font-size: 1.8rem;
	margin-right: 1.5rem;
}
.comment-title {
	font-family: OpenSans-Semibold;
	text-transform: uppercase;
	font-size: 1.4rem;
	margin-top: 2rem;
}
.commenter {
	margin-top: 1.5rem;
	font-family: OpenSans-Semibold;
	font-size: 1.4rem;
	p {
		display: inline-block;
		span {
			text-transform: capitalize;
		}
	}
}
.commenter-thumb {
	width: 3.5rem;
	@include border-radius(2rem);
	display: inline-block;
	vertical-align: middle;
	margin-right: 1rem;
}
.commenter-por {
	font-size: 1.2rem
}
.commenter-name {
	font-weight: bolder;
}
.comment {
	font-size: 1.4rem;
	margin-top: 1rem;
	margin-bottom: 4rem;
}
.mod-aside-playlist {
	padding:4rem 2.4rem ;
	.row {
		margin-bottom: 4rem;
	}
}
/*////////////////*/

/*Mis Favoritas*/
.favorites-songs {
	@extend .songs-wrapper;
	border-top: 0;
	
}

/*////////////////*/

/*Curator Profile*/
.mod-curator-profile {
	@extend .mod-playlist-detail-content;
}
.profile-picture {
	img {
		width: 100%;
		@include border-radius(100rem);
		}
}
.profile-curator-wrapper {
	@extend .playlist-detail-wrapper;
}

.profile-curator-wallpaper {	
	position: absolute !important;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	overflow: hidden;
	left: 0;
	right: 0;
	z-index: -999;
	display:block;
	filter: blur(5px);
	height: 100%;
}
.profile-curator-wallpaper > .overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	background-color: rgba(0,0,0,0.5); /*dim the background*/
}
.curator-ubication {
	margin-top: 3rem;
	font-size: 1.4rem;
	font-family: OpenSans-Light;
	color: $grey-default;
	text-transform: uppercase;
}
.followers-info {
	li {
		display: inline-block;
		@extend .curator-ubication;
		margin-top: 0;
		padding: 0 1rem;
		border-right: solid 1px #fff;
		&:last-child {
			border-right: 0;
		}
		span {
			color: #fff;
		}
	}
}
.accomplishments {
	li {
		display: inline-block;
		margin-right: 1rem;
		margin-top: 1rem;
	}
}
.mod-playlist-curator {
	padding: 4rem 0;
}
.mod-playlist-curator-content {
	padding: 4rem 2.4rem;
}
.recomended-curators {
	li {
		font-family: OpenSans-Light;
		font-size: 1.8rem;
		text-transform: capitalize;
		margin-bottom: 1rem;
		img {
			width: 3.5rem;
			@include border-radius(3rem);
			display: inline-block;
			vertical-align: middle;
		}
	}
}
.fav-songs-list {
	li {
		font-family: OpenSans-Light;
		font-size: 1.8rem;
		text-transform: capitalize;
		margin-bottom: 2rem;
		.song-name {
			font-family: OpenSans-Bold;
		}
	}
}
.aside-simple-container {
	margin-bottom: 6rem;
}
/*////////////////*/

/*Offline*/
.my-songs {
	.name-song {
		@extend .name-playlist;
		font-family: OpenSans-Semibold !important;
		border-bottom: 0 !important;
	}
	.artist {
		line-height: 1;
	}
}
/*////////////////*/

/*New Mixtape*/
.new-mixtape {
	position: fixed;
	top: 50px;
	right: 0px;
	height: 100%;
	background: $generic-bg;
	color: #fff;
}
.drag-songs-wrapper {	
	margin-top: 3rem;
	border-top: solid 1px $grey-dark;

}
.drag-songs-placeholder {
	width: 100%;
	min-height: 40vh;
	max-height: 50vh;
	overflow: scroll;	
	text-align: center;
	color: $grey-dark;
	padding: 1rem;
	font-size: 1.4rem;
	p {
		margin-top: 1rem;
	}
}
.info-and-save {
	    border-top: solid 1px #808080;
	    padding: 2rem 0;
}
/*////////////////*/

/*Create Playlist*/
.song-library {
	margin-top: 2rem;
	border-top: solid 1px $grey-dark;
	padding: 1.5rem 0.5rem;
}
.song-lib-list {
	font-size: 1.3rem;
	text-transform: capitalize;
	li {
		margin-bottom: 2rem;
		cursor: pointer;
		&:after {
   			clear: both;
		}
		&:before, &:after {
    		content: " ";
   			display: table;
		}
		span {
			display: block;
			font-family: OpenSans-Light;
			font-size: 1.1rem;

		}
		.btn {margin-right: 1rem;display: none;}
	}
	li.active {
		color: $prim-pink;
		.btn {
			display: block;
		}
		
	}
}
.add-playlist-thumb  {
	width: 8rem;
	height: 8rem;
	background: $prim-blue;
	margin-right: 1rem;
}
.add-banner-playlist {
	width: 100%;
	height: 8rem;
	background: $prim-blue;
	margin: 2.5rem 0;
}
/*////////////////*/

/*Login*/
.logo-login {
	display: inline-block;
	width: 25rem;
	margin-bottom: 4rem;
}
.login-social-media {
	margin-top: 2rem
}
.option-separator {
	    width: 100%;
    text-align: center;
    display: inline-block;
    border-bottom: solid 1px;
    line-height: 0;
    font-size: 1.4rem;
    font-family: OpenSans-Light;
    text-transform: uppercase;
    margin: 4rem 0;
}
.login-section {
	max-width: 50%;
	margin: auto;
	&:first-child {
		padding-right: 4rem;
	}
	&:last-child {
		padding-left: 4rem;
	}
}
/*////////////////*/

/*actividad*/
.notifications {
	margin-top: 3rem;
	.date {
		color: $grey-medium;
	text-transform: capitalize;
	text-indent: 2rem;
	padding: 0.5rem 0;
	border-bottom: solid 1px $grey-dark;
	}
	
}
.notification-wrapper {
	padding: 2rem 0;
	    border-bottom: solid 1px $grey-dark;
}
.noti-thumb {
	width: 3rem;
	@include border-radius(2rem);
	margin-right: 1rem;
}
.notification-user { width: 3rem;}
.notification-info {
	    line-height: 1.4;
	p > span,.preview-comment {color: $grey-medium;}
}
.achivements-wrapper {
	padding: 3rem 8rem; 
}
.achivements-row {
	margin-bottom: 2rem;
}
/*////////////////*/

/*Quienes Somos*/
.about-us-banner {
	height: 50rem;
	background: $prim-blue;
	margin-bottom: 3.6rem;
}
.text-default {
	font-size: 2rem;
	margin: 3rem 0;
}
.team-wrapper {
	padding: 3rem 0 4rem 0;
}
.team-member {
	img {
		@include border-radius(50rem);
		margin-bottom: 1.5rem;
		max-width: 100%;
	}
	
}
.member-name {
	font-family: OpenSans-Semibold;
	text-transform: capitalize;
	margin-bottom: 0.5rem;
}
.member-roll {
	font-family: OpenSans-Light;
	text-transform: capitalize;
}
.team-picture {
	height: 25rem;
	background: $prim-blue;
}
/*////////////////*/

/*CopyRight*/
.default-banner {
	@extend .about-us-banner;
	height: 35rem;	
}
/*////////////////*/

/* RANKING */

.ranking-item {
	border-bottom: 1px solid $gray-light;
}

.ranking-info {
	padding: 20px 0 0 0;
	li{
		display: table-cell;
		padding: 0 0 0 35px;
		h4{
			.fa {
				color: $prim-pink;
			}
		}
	}
}

.ranking-playlist-title {
	display: inline-block;
	padding: 30px 0 0 25px
}