/*Gradiente Vertical*/
/**
 * ALL YOUR DIRTY UGLY HACKS ARE BELONG TO US.
 */
/**
 * Make elements draggable in old WebKit
 */
[draggable] {
  user-select: none;
  -khtml-user-drag: element;
  -webkit-user-drag: element; }

/**
 * Make webkit scrollbars a bit more good looking on non-mac systems.
 */
html.non-mac ::-webkit-scrollbar {
  width: 10px;
  height: 10px; }

html.non-mac ::-webkit-scrollbar-button {
  width: 0px;
  height: 0px; }

html.non-mac ::-webkit-scrollbar-thumb {
  background: #181818;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50px; }

html.non-mac ::-webkit-scrollbar-thumb:hover {
  background: #303030; }

html.non-mac ::-webkit-scrollbar-thumb:active {
  background: #181818; }

html.non-mac ::-webkit-scrollbar-track {
  background: #181818;
  border: 0px none #ffffff;
  border-radius: 50px; }

html.non-mac ::-webkit-scrollbar-track:hover {
  background: #181818; }

html.non-mac ::-webkit-scrollbar-track:active {
  background: #333333; }

html.non-mac ::-webkit-scrollbar-corner {
  background: transparent; }

@keyframes plyr-progress {
  to {
    background-position: 40px 0; } }

.plyr {
  position: relative;
  max-width: 100%;
  min-width: 290px; }
  .plyr,
  .plyr *,
  .plyr *::after,
  .plyr *::before {
    box-sizing: border-box; }
  .plyr a, .plyr button, .plyr input, .plyr label {
    touch-action: manipulation; }
  .plyr__sr-only {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .plyr__video-wrapper {
    position: relative; }
  .plyr video,
  .plyr audio {
    width: 100%;
    height: auto;
    vertical-align: middle; }
  .plyr__video-embed {
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
    overflow: hidden;
    background: #000; }
    .plyr__video-embed iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      user-select: none; }
    .plyr__video-embed > div {
      position: relative;
      padding-bottom: 200%;
      transform: translateY(-35.95%); }
    .plyr__video-embed.plyr iframe {
      pointer-events: none; }
  .plyr__captions {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 12px 12px 18px;
    color: #fff;
    font-size: 20px;
    text-align: center;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }
    .plyr__captions span {
      border-radius: 2px;
      padding: 3px 10px;
      background: rgba(0, 0, 0, 0.1); }
    .plyr__captions span:empty {
      display: none; }
    @media (min-width: 768px) {
      .plyr__captions {
        font-size: 24px; } }
  .plyr--captions-active .plyr__captions {
    display: block; }
  .plyr--fullscreen-active .plyr__captions {
    font-size: 32px; }
  .plyr__controls {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    position: relative;
    padding: 6px;
    background: #fff;
    line-height: 1;
    text-align: center;
    box-shadow: 0 1px 1px rgba(52, 63, 74, 0.8); }
    .plyr__controls::after {
      content: '';
      display: table;
      clear: both; }
    .plyr__controls--right {
      display: block;
      margin: 6px auto 0; }
    @media (min-width: 560px) {
      .plyr__controls--left {
        float: left; }
      .plyr__controls--right {
        float: right;
        margin-top: 0; } }
    .plyr__controls button {
      display: inline-block;
      vertical-align: middle;
      margin: 0 2px;
      padding: 3px 6px;
      overflow: hidden;
      border: 0;
      background: transparent;
      border-radius: 3px;
      cursor: pointer;
      color: #ff7d2e;
      transition: background .3s ease, color .3s ease, opacity .3s ease; }
      .plyr__controls button svg {
        width: 18px;
        height: 18px;
        display: block;
        fill: currentColor;
        transition: fill .3s ease; }
      .plyr__controls button.tab-focus:hover, .plyr__controls button:hover {
        background: #ff7d2e;
        color: #565D64; }
      .plyr__controls button:focus {
        outline: 0; }
    .plyr__controls .icon--exit-fullscreen,
    .plyr__controls .icon--muted,
    .plyr__controls .icon--captions-on {
      display: none; }
    .plyr__controls .plyr__time {
      display: inline-block;
      vertical-align: middle;
      margin-left: 6px;
      color: #ff7d2e;
      font-weight: 600;
      font-size: 14px; }
    .plyr__controls .plyr__time + .plyr__time {
      display: none; }
      @media (min-width: 560px) {
        .plyr__controls .plyr__time + .plyr__time {
          display: inline-block; } }
      .plyr__controls .plyr__time + .plyr__time::before {
        content: '\2044';
        margin-right: 6px; }
  .plyr__tooltip {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    bottom: 100%;
    margin-bottom: 6px;
    padding: 6px 9px;
    opacity: 0;
    background: #fff;
    box-shadow: 0 0 5px rgba(52, 63, 74, 0.9), 0 0 0 1px rgba(52, 63, 74, 0.9);
    border-radius: 3px;
    color: #ff7d2e;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
    transform: translate(-50%, 10px) scale(0.8);
    transform-origin: 50% 100%;
    transition: transform .2s .1s ease, opacity .2s .1s ease, visibility .3s ease; }
    .plyr__tooltip::after, .plyr__tooltip::before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 100%;
      left: 50%;
      transform: translateX(-50%); }
    .plyr__tooltip::after {
      bottom: -8px;
      border-right: 7px solid transparent;
      border-top: 7px solid rgba(52, 63, 74, 0.9);
      border-left: 7px solid transparent;
      z-index: 1; }
    .plyr__tooltip::before {
      bottom: -6px;
      border-right: 6px solid transparent;
      border-top: 6px solid #fff;
      border-left: 6px solid transparent;
      z-index: 2; }
  .plyr button:hover .plyr__tooltip,
  .plyr button.tab-focus:focus .plyr__tooltip, .plyr__tooltip--visible {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0) scale(1); }
  .plyr button:hover .plyr__tooltip {
    z-index: 3; }
  .plyr input[type='range'].tab-focus:focus {
    outline: thin dotted rgba(52, 63, 74, 0.2);
    outline-offset: 3px; }
  .plyr__progress {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 6px;
    background: rgba(86, 93, 100, 0.8); }
    .plyr__progress--buffer[value], .plyr__progress--played[value], .plyr__progress--seek[type='range'] {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 6px;
      margin: 0;
      padding: 0;
      vertical-align: top;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      background: transparent; }
    .plyr__progress--buffer[value]::-webkit-progress-bar, .plyr__progress--played[value]::-webkit-progress-bar {
      background: transparent;
      transition: width .2s ease; }
    .plyr__progress--buffer[value]::-webkit-progress-value, .plyr__progress--played[value]::-webkit-progress-value {
      background: currentColor;
      transition: width .2s ease; }
    .plyr__progress--buffer[value]::-moz-progress-bar, .plyr__progress--played[value]::-moz-progress-bar {
      background: currentColor;
      transition: width .2s ease; }
    .plyr__progress--played[value] {
      z-index: 2;
      color: #ff7d2e; }
    .plyr__progress--buffer[value] {
      color: rgba(86, 93, 100, 0.75); }
    .plyr__progress--seek[type='range'] {
      z-index: 4;
      cursor: pointer;
      outline: 0; }
      .plyr__progress--seek[type='range']::-webkit-slider-runnable-track {
        background: none;
        border: 0; }
      .plyr__progress--seek[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        background: transparent;
        border: 0;
        width: 1px;
        height: 6px; }
      .plyr__progress--seek[type='range']::-moz-range-track {
        background: none;
        border: 0; }
      .plyr__progress--seek[type='range']::-moz-range-thumb {
        -moz-appearance: none;
        background: transparent;
        border: 0;
        width: 1px;
        height: 6px; }
      .plyr__progress--seek[type='range']::-ms-track {
        color: transparent;
        background: none;
        border: 0; }
      .plyr__progress--seek[type='range']::-ms-fill-lower, .plyr__progress--seek[type='range']::-ms-fill-upper {
        background: none;
        border: 0; }
      .plyr__progress--seek[type='range']::-ms-thumb {
        background: transparent;
        border: 0;
        width: 1px;
        height: 6px; }
      .plyr__progress--seek[type='range']:focus {
        outline: 0; }
      .plyr__progress--seek[type='range']::-moz-focus-outer {
        border: 0; }
    .plyr__progress .plyr__tooltip {
      left: 0; }
  .plyr--is-touch .plyr--seek[type='range']::-webkit-slider-thumb {
    width: 24px;
    transform: translateX(-50%); }
  .plyr--is-touch .plyr--seek[type='range']::-moz-range-thumb {
    width: 24px;
    transform: translateX(-50%); }
  .plyr--is-touch .plyr--seek[type='range']::-ms-thumb {
    width: 24px;
    transform: translateX(-50%); }
  .plyr--loading .plyr__progress--buffer {
    animation: plyr-progress 1s linear infinite;
    background-size: 40px 40px;
    background-repeat: repeat-x;
    background-color: rgba(86, 93, 100, 0.75);
    background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.85) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.85) 50%, rgba(0, 0, 0, 0.85) 75%, transparent 75%, transparent);
    color: transparent; }
  .plyr__controls [data-plyr='pause'],
  .plyr--playing .plyr__controls [data-plyr='play'] {
    display: none; }
  .plyr--playing .plyr__controls [data-plyr='pause'] {
    display: inline-block; }
  .plyr__volume[type='range'] {
    display: inline-block;
    vertical-align: middle;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100px;
    margin: 0 6px 0 0;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: none; }
    .plyr__volume[type='range']::-webkit-slider-runnable-track {
      height: 6px;
      background: #e6e6e6;
      border: 0;
      border-radius: 3px; }
    .plyr__volume[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      margin-top: -3px;
      height: 12px;
      width: 12px;
      background: #ff7d2e;
      border: 0;
      border-radius: 100%;
      transition: background .3s ease;
      cursor: ew-resize; }
    .plyr__volume[type='range']::-moz-range-track {
      height: 6px;
      background: #e6e6e6;
      border: 0;
      border-radius: 3px; }
    .plyr__volume[type='range']::-moz-range-thumb {
      height: 12px;
      width: 12px;
      background: #ff7d2e;
      border: 0;
      border-radius: 100%;
      transition: background .3s ease;
      cursor: ew-resize; }
    .plyr__volume[type='range']::-ms-track {
      height: 6px;
      background: transparent;
      border-color: transparent;
      border-width: 3px 0;
      color: transparent; }
    .plyr__volume[type='range']::-ms-fill-lower, .plyr__volume[type='range']::-ms-fill-upper {
      height: 6px;
      background: #e6e6e6;
      border: 0;
      border-radius: 3px; }
    .plyr__volume[type='range']::-ms-thumb {
      height: 12px;
      width: 12px;
      background: #ff7d2e;
      border: 0;
      border-radius: 100%;
      transition: background .3s ease;
      cursor: ew-resize; }
    .plyr__volume[type='range']:focus {
      outline: 0; }
      .plyr__volume[type='range']:focus::-webkit-slider-thumb {
        background: #ff7d2e; }
      .plyr__volume[type='range']:focus::-moz-range-thumb {
        background: #ff7d2e; }
      .plyr__volume[type='range']:focus::-ms-thumb {
        background: #ff7d2e; }
  .plyr--is-ios .plyr__volume,
  .plyr--is-ios [data-plyr='mute'],
  .plyr--is-ios.plyr--audio .plyr__controls--right {
    display: none; }
  .plyr--is-ios.plyr--audio .plyr__controls--left {
    float: none; }
  .plyr--audio .plyr__controls {
    padding-top: 12px; }
  .plyr--audio .plyr__progress {
    bottom: auto;
    top: 0;
    background: #313131; }
  .plyr--fullscreen, .plyr--fullscreen-active {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 10000000;
    background: #000; }
    .plyr--fullscreen video, .plyr--fullscreen-active video {
      height: 100%; }
    .plyr--fullscreen .plyr__video-wrapper, .plyr--fullscreen-active .plyr__video-wrapper {
      height: 100%;
      width: 100%; }
    .plyr--fullscreen .plyr__controls, .plyr--fullscreen-active .plyr__controls {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0; }
  .plyr--fullscreen-active.plyr--fullscreen--hide-controls.plyr--playing .plyr__controls, .plyr.plyr--fullscreen.plyr--fullscreen--hide-controls.plyr--playing .plyr__controls {
    transform: translateY(100%) translateY(3px);
    transition: transform .3s .2s ease; }
  .plyr--fullscreen-active.plyr--fullscreen--hide-controls.plyr--playing .plyr__captions, .plyr.plyr--fullscreen.plyr--fullscreen--hide-controls.plyr--playing .plyr__captions {
    bottom: 3px;
    transition: bottom .3s .2s ease; }
  .plyr--fullscreen-active.plyr--fullscreen--hide-controls.plyr--playing.plyr--hover .plyr__controls, .plyr.plyr--fullscreen.plyr--fullscreen--hide-controls.plyr--playing.plyr--hover .plyr__controls {
    transform: translateY(0); }
  .plyr.plyr--fullscreen .plyr__captions,
  .plyr--fullscreen-active .plyr__captions,
  .plyr--fullscreen--hide-controls.plyr--fullscreen-active.plyr--playing.plyr--hover .plyr__captions {
    top: auto;
    bottom: 90px; }
    @media (min-width: 560px) {
      .plyr.plyr--fullscreen .plyr__captions,
      .plyr--fullscreen-active .plyr__captions,
      .plyr--fullscreen--hide-controls.plyr--fullscreen-active.plyr--playing.plyr--hover .plyr__captions {
        bottom: 60px; } }
  .plyr--fullscreen-active .icon--exit-fullscreen,
  .plyr--muted .plyr__controls .icon--muted,
  .plyr--captions-active .plyr__controls .icon--captions-on {
    display: block; }
    .plyr--fullscreen-active .icon--exit-fullscreen + svg,
    .plyr--muted .plyr__controls .icon--muted + svg,
    .plyr--captions-active .plyr__controls .icon--captions-on + svg {
      display: none; }
  .plyr [data-plyr='captions'],
  .plyr [data-plyr='fullscreen'] {
    display: none; }
  .plyr--captions-enabled [data-plyr='captions'],
  .plyr--fullscreen-enabled [data-plyr='fullscreen'] {
    display: inline-block; }

/**
 * A stripped-down style of NProgress.
 * Heck, rstacruz (the author) even said:
 * > The included CSS file is pretty minimal... in fact, feel free to scrap it and make your own!
 */
#nprogress {
  /* Make clicks pass-through */
  pointer-events: none;
  /* Fancy blur effect */ }
  #nprogress .bar {
    background: #ff7d2e;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px; }
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #ff7d2e, 0 0 5px #ff7d2e;
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px); }

.nprogress-custom-parent {
  overflow: hidden;
  position: relative; }
  .nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
    position: absolute; }

*, *:before, *:after {
  box-sizing: border-box;
  outline: none; }

body, html {
  overflow: auto; }

input, select, button, textarea, .btn {
  appearance: none;
  border: 0;
  outline: 0;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 300;
  padding: 4px 8px;
  border-radius: 3px; }
  input:required, input:invalid, select:required, select:invalid, button:required, button:invalid, textarea:required, textarea:invalid, .btn:required, .btn:invalid {
    box-shadow: none; }
  input.dirty, select.dirty, button.dirty, textarea.dirty, .btn.dirty {
    background: #fff5b0; }
  input[type="search"], select[type="search"], button[type="search"], textarea[type="search"], .btn[type="search"] {
    border-radius: 12px;
    height: 24px;
    padding: 0 6px; }
  input[type="text"], select[type="text"], button[type="text"], textarea[type="text"], .btn[type="text"] {
    display: block; }
  input:focus, select:focus, button:focus, textarea:focus, .btn:focus {
    outline: none !important; }
  input::-moz-focus-inner, select::-moz-focus-inner, button::-moz-focus-inner, textarea::-moz-focus-inner, .btn::-moz-focus-inner {
    border: 0 !important; }

input[type="checkbox"] {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  background: transparent;
  color: #555;
  clear: none;
  cursor: pointer;
  display: inline-block;
  line-height: 0;
  height: 16px;
  margin: -4px 4px 0 0;
  outline: 0;
  padding: 0 !important;
  text-align: center;
  vertical-align: middle;
  width: 16px;
  min-width: 16px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); }
  input[type="checkbox"]:checked {
    background: #fff; }
    input[type="checkbox"]:checked:before {
      font-family: FontAwesome;
      content: "\f00c";
      color: #ff7d2e;
      display: inline-block;
      vertical-align: middle;
      width: 14px;
      height: 14px;
      line-height: 14px;
      text-align: center; }

a, a:link, a:visited {
  /*color: $colorLink;*/
  text-decoration: none;
  cursor: pointer; }

.clear::after, .clearfix::after {
  content: " ";
  clear: both;
  display: block; }

.side {
  width: 256px; }

.ir {
  color: transparent;
  font: 0/0 serif; }

.control {
  cursor: pointer;
  color: #aaa; }
  .control:hover {
    color: #fff; }

p {
  line-height: 20px; }

.help {
  opacity: .7;
  font-size: 90%;
  line-height: 16px; }

label {
  font-size: 14px;
  margin-bottom: 8px;
  display: block; }

button, input[type="submit"], input[type="reset"], input[type="button"], .btn {
  background: #0191f7;
  color: #fff;
  font-size: 14px;
  padding: 8px 12px;
  cursor: pointer; }
  button:active, input[type="submit"]:active, input[type="reset"]:active, input[type="button"]:active, .btn:active {
    box-shadow: inset 0px 10px 10px -10px black; }
  button.btn-green, input[type="submit"].btn-green, input[type="reset"].btn-green, input[type="button"].btn-green, .btn.btn-green {
    background-color: #56a052; }
    button.btn-green:hover, input[type="submit"].btn-green:hover, input[type="reset"].btn-green:hover, input[type="button"].btn-green:hover, .btn.btn-green:hover {
      background-color: #447e41; }
  button.btn-white, input[type="submit"].btn-white, input[type="reset"].btn-white, input[type="button"].btn-white, .btn.btn-white {
    background-color: #fff;
    color: #333; }
    button.btn-white:hover, input[type="submit"].btn-white:hover, input[type="reset"].btn-white:hover, input[type="button"].btn-white:hover, .btn.btn-white:hover {
      background-color: #e6e6e6; }
  button.btn-red, input[type="submit"].btn-red, input[type="reset"].btn-red, input[type="button"].btn-red, .btn.btn-red {
    background-color: #c34848; }
    button.btn-red:hover, input[type="submit"].btn-red:hover, input[type="reset"].btn-red:hover, input[type="button"].btn-red:hover, .btn.btn-red:hover {
      background-color: #a33535; }
  button.btn-blue, input[type="submit"].btn-blue, input[type="reset"].btn-blue, input[type="button"].btn-blue, .btn.btn-blue {
    background-color: #4c769a; }
    button.btn-blue:hover, input[type="submit"].btn-blue:hover, input[type="reset"].btn-blue:hover, input[type="button"].btn-blue:hover, .btn.btn-blue:hover {
      background-color: #3b5c78; }
  button.btn-grey, input[type="submit"].btn-grey, input[type="reset"].btn-grey, input[type="button"].btn-grey, .btn.btn-grey {
    background-color: #3c3c3c; }
    button.btn-grey:hover, input[type="submit"].btn-grey:hover, input[type="reset"].btn-grey:hover, input[type="button"].btn-grey:hover, .btn.btn-grey:hover {
      background-color: #232323; }
  button.btn-orange, input[type="submit"].btn-orange, input[type="reset"].btn-orange, input[type="button"].btn-orange, .btn.btn-orange {
    background-color: #ff7d2e; }
    button.btn-orange:hover, input[type="submit"].btn-orange:hover, input[type="reset"].btn-orange:hover, input[type="button"].btn-orange:hover, .btn.btn-orange:hover {
      background-color: #fa5e00; }

.tabs .header {
  border-bottom: 1px solid #5c5c5c; }
  .tabs .header a {
    padding: 8px 12px;
    margin-left: 4px;
    border-radius: 4px 4px 0 0;
    text-transform: uppercase;
    color: gainsboro;
    opacity: .4;
    border: 1px solid #5c5c5c;
    margin-bottom: -1px;
    float: left; }
    .tabs .header a.active {
      border-bottom: 1px solid #212121;
      opacity: 1; }

.tabs .panes {
  padding: 16px 0; }

.tabs.tabs-white .header {
  border-bottom-color: #aaa; }
  .tabs.tabs-white .header a {
    color: #333;
    border-color: #aaa; }
    .tabs.tabs-white .header a.active {
      border-bottom-color: #fff; }

.form-row {
  margin-top: 16px;
  position: relative; }

/* Playlist */
.meta-data-info {
  display: none; }

.img-center {
  display: block;
  margin: 0 auto; }

.reclenty-played-song {
  cursor: move; }

.content-sound {
  border: 1px dotted white;
  min-height: 80px;
  height: auto;
  clear: both; }

.content_image_id3_update {
  width: 252px;
  height: 252px;
  display: block;
  background-color: #29ABE2;
  cursor: pointer;
  margin: 0 auto;
  border: 1px dotted white; }

.archivo {
  display: none !important; }

.add-playlist-thumb, .add-banner-playlist {
  cursor: pointer; }

.add-playlist-thumb:hover, .add-banner-playlist:hover {
  border: 1px dotted white; }

.content-playlist {
  margin-top: -25px; }

.all-info-sound {
  display: inline-block;
  width: 92%;
  /*height: 62px;*/
  padding-left: 15px; }

.arte-sound {
  width: 13%;
  padding-right: 10px;
  padding-left: 10px; }

.info-sound {
  width: 85%; }

.reclenty-played-song {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-right: 5px;
  margin-right: 18px; }

.reclenty-played-song:hover {
  background-color: #525252; }

.info-sound p {
  display: block;
  width: auto; }

.info-sound p:first-child {
  font-size: 14px;
  color: #29ABE2; }

.btn-sound {
  width: 5%; }

.btn-sound button {
  background-color: transparent !important;
  float: right;
  width: 100%; }

.publicidad-container {
  height: 100vh; }

.img-publicidad-panel {
  background-size: 100% 100%;
  background-repeat: no-repeat; }

/* scrollbar*/
.ps-scrollbar-x {
  display: none; }

.img-container {
  overflow: hidden; }

.banner-publicidad {
  display: block;
  width: 100% !important;
  min-height: 100% !important;
  margin: auto; }

#stalkingModal {
  max-width: 600px;
  margin: auto; }

.stalking-avatar {
  border-radius: 50%; }

header {
  margin-bottom: 6em; }

/* Mixtape */
#arrastra-placeholder {
  padding: 3rem; }

.ver-album:hover {
  cursor: pointer; }

.song-list-item {
  cursor: move;
  z-index: 999; }

#song-list > .song-list-item > .drop-hide {
  display: block; }

#drop-songs .row {
  margin-left: 0px !important;
  margin-right: 0px !important; }

#drop-songs .song-list-item {
  font-size: 11px !important;
  color: #fff !important;
  width: 100% !important;
  margin-bottom: 1rem !important;
  padding: 15px 0 !important;
  border: 1px solid #fff !important; }
  #drop-songs .song-list-item .drop-hide {
    display: none !important; }
  #drop-songs .song-list-item .song-list-item-title {
    text-align: left;
    top: 5px; }
    #drop-songs .song-list-item .song-list-item-title .drop-hide {
      display: none !important; }

#drop-songs > .song-list-item > .col-md-1 > .delete-from-mixtape {
  visibility: visible !important; }

/*!
 * bootstrap-fileinput v4.3.9
 * http://plugins.krajee.com/file-input
 *
 * Krajee default styling for bootstrap-fileinput.
 *
 * Author: Kartik Visweswaran
 * Copyright: 2014 - 2017, Kartik Visweswaran, Krajee.com
 *
 * Licensed under the BSD 3-Clause
 * https://github.com/kartik-v/bootstrap-fileinput/blob/master/LICENSE.md
 */
.file-loading {
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  font-size: 999px;
  text-align: right;
  color: #fff;
  background: transparent url("../img/loading.gif") top left no-repeat;
  border: none; }

.file-object {
  margin: 0 0 -5px 0;
  padding: 0; }

.btn-file {
  position: relative;
  overflow: hidden; }

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  text-align: right;
  opacity: 0;
  background: none repeat scroll 0 0 transparent;
  cursor: inherit;
  display: block; }

.btn-cusica-file-modal {
  background-image: linear-gradient(#f26450, #ed4b63) !important;
  border: none !important;
  border-radius: 30px !important;
  max-height: 35px !important;
  width: 150px !important;
  font-size: 12px !important;
  font-weight: 300 !important; }

.file-caption-name {
  display: inline-block;
  overflow: hidden;
  height: 20px;
  word-break: break-all; }

.input-group-lg .file-caption-name {
  height: 25px; }

.file-zoom-dialog {
  text-align: left; }

.file-error-message {
  color: #a94442;
  background-color: #f2dede;
  margin: 5px;
  border: 1px solid #ebccd1;
  border-radius: 4px;
  padding: 15px; }

.file-error-message pre, .file-error-message ul {
  margin: 0;
  text-align: left; }

.file-error-message pre {
  margin: 5px 0; }

.file-caption-disabled {
  background-color: #EEEEEE;
  cursor: not-allowed;
  opacity: 1; }

.file-preview {
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 5px;
  width: 100%;
  margin-bottom: 5px; }

.krajee-default.file-preview-frame {
  position: relative;
  display: table;
  margin: 8px;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 5px 0 #a2958a;
  padding: 6px;
  float: left;
  text-align: center; }

.krajee-default.file-preview-frame:not(.file-preview-error):hover {
  box-shadow: 3px 3px 5px 0 #333; }

.krajee-default.file-preview-frame .kv-file-content {
  height: 170px; }

.krajee-default.file-preview-frame .file-thumbnail-footer {
  height: 70px; }

.krajee-default .file-preview-image {
  vertical-align: middle;
  image-orientation: from-image; }

.krajee-default .file-preview-text {
  display: block;
  color: #428bca;
  border: 1px solid #ddd;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  outline: none;
  padding: 8px;
  resize: none; }

.krajee-default .file-preview-html {
  border: 1px solid #ddd;
  padding: 8px;
  overflow: auto; }

.krajee-default[data-template="audio"] .file-preview-audio {
  display: table-cell;
  vertical-align: middle;
  height: 170px;
  border: 1px solid #ddd;
  border-radius: 5px; }

.krajee-default .file-preview-audio audio {
  vertical-align: middle; }

.krajee-default .file-zoom-dialog .file-preview-text {
  font-size: 1.2em; }

.krajee-default .file-preview-other {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  vertical-align: middle;
  padding: 10px; }

.krajee-default .file-preview-other:hover {
  opacity: 0.8; }

.krajee-default .file-actions, .krajee-default .file-other-error {
  text-align: left; }

.krajee-default .file-other-icon {
  font-size: 8em; }

.krajee-default .file-actions {
  margin-top: 15px; }

.krajee-default .file-footer-buttons {
  float: right; }

.krajee-default .file-footer-caption {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 160px;
  text-align: center;
  padding-top: 4px;
  font-size: 11px;
  color: #777;
  margin: 5px auto; }

.krajee-default .file-preview-error {
  opacity: 0.65;
  box-shadow: none; }

.krajee-default .file-preview-frame:not(.file-preview-error) .file-footer-caption:hover {
  color: #000; }

.krajee-default .file-drag-handle, .krajee-default .file-upload-indicator {
  position: absolute;
  text-align: center;
  bottom: -6px;
  left: -6px;
  padding: 8px 8px 1px 3px;
  border-left: none;
  border-bottom: none;
  border-right: 1px solid #8a6d3b;
  border-top: 1px solid #8a6d3b;
  border-top-right-radius: 24px;
  font-size: 12px; }

.krajee-default .file-drag-handle {
  background-color: #d9edf7;
  border-color: #bce8f1; }

.krajee-default .file-upload-indicator {
  font-size: 13px;
  background-color: #fcf8e3;
  border-color: #faebcc;
  padding-bottom: 0; }

.krajee-default.file-preview-error .file-upload-indicator {
  background-color: #f2dede;
  border-color: #ebccd1; }

.krajee-default.file-preview-success .file-upload-indicator {
  background-color: #dff0d8;
  border-color: #d6e9c6; }

.krajee-default.file-preview-loading .file-upload-indicator {
  background-color: #e5e5e5;
  border-color: #777; }

.krajee-default .file-thumb-progress {
  height: 10px; }

.krajee-default .file-thumb-progress .progress, .krajee-default .file-thumb-progress .progress-bar {
  height: 10px;
  font-size: 9px;
  line-height: 10px; }

.krajee-default .file-thumbnail-footer {
  position: relative; }

.krajee-default .file-thumb-progress {
  position: absolute;
  top: 35px;
  left: 0;
  right: 0; }

.krajee-default.kvsortable-ghost {
  background: #e1edf7;
  border: 2px solid #a1abff; }

/* noinspection CssOverwrittenProperties */
.file-zoom-dialog .file-other-icon {
  font-size: 8em;
  font-size: 65vmin; }

.file-input-new .file-preview, .file-input-new .close, .file-input-new .glyphicon-file,
.file-input-new .fileinput-remove-button, .file-input-new .fileinput-upload-button,
.file-input-ajax-new .fileinput-remove-button, .file-input-ajax-new .fileinput-upload-button {
  display: none; }

.file-caption-main {
  width: 100%; }

.file-input-ajax-new .no-browse .input-group-btn,
.file-input-new .no-browse .input-group-btn {
  display: none; }

.file-input-ajax-new .no-browse .form-control,
.file-input-new .no-browse .form-control {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.file-thumb-loading {
  background: transparent url("../img/loading.gif") no-repeat scroll center center content-box !important; }

.file-sortable .file-drag-handle {
  cursor: move;
  cursor: -webkit-grabbing;
  opacity: 1; }

.file-sortable .file-drag-handle:hover {
  opacity: 0.7; }

.file-drop-zone {
  border: 1px dashed #aaa;
  border-radius: 4px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  margin: 12px 15px 12px 12px;
  padding: 5px; }

.file-drop-zone-title {
  color: #aaa;
  font-size: 1.6em;
  padding: 85px 10px;
  cursor: default; }

.file-preview .clickable,
.clickable .file-drop-zone-title {
  cursor: pointer; }

.file-drop-zone.clickable:hover {
  border: 2px dashed #999; }

.file-drop-zone.clickable:focus {
  border: 2px solid #5acde2; }

.file-drop-zone .file-preview-thumbnails {
  cursor: default; }

.file-highlighted {
  border: 2px dashed #999 !important;
  background-color: #f0f0f0; }

.file-uploading {
  background: url("../img/loading-sm.gif") no-repeat center bottom 10px;
  opacity: 0.65; }

.file-zoom-fullscreen.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.file-zoom-fullscreen .modal-dialog {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0; }

.file-zoom-fullscreen .modal-content {
  border-radius: 0;
  box-shadow: none; }

.file-zoom-fullscreen .modal-body {
  overflow-y: auto; }

.file-zoom-dialog .modal-body {
  position: relative !important; }

.file-zoom-dialog .btn-navigate {
  position: absolute;
  padding: 0;
  margin: 0;
  background: transparent;
  text-decoration: none;
  outline: none;
  opacity: 0.7;
  top: 45%;
  font-size: 4em;
  color: #1c94c4; }

.file-zoom-dialog .floating-buttons {
  position: absolute;
  top: 5px;
  right: 10px; }

.floating-buttons, .floating-buttons .btn {
  z-index: 3000; }

.file-zoom-dialog .kv-zoom-actions .btn,
.floating-buttons .btn {
  margin-left: 3px; }

.file-zoom-dialog .btn-navigate:not([disabled]):hover,
.file-zoom-dialog .btn-navigate:not([disabled]):focus {
  outline: none;
  box-shadow: none;
  opacity: 0.5; }

.file-zoom-dialog .btn-navigate[disabled] {
  opacity: 0.3; }

.file-zoom-dialog .btn-prev {
  left: 1px; }

.file-zoom-dialog .btn-next {
  right: 1px; }

.file-zoom-content {
  height: 480px;
  text-align: center; }

.file-preview-initial.sortable-chosen {
  background-color: #d9edf7; }

/* IE 10 fix */
.btn-file ::-ms-browse {
  width: 100%;
  height: 100%; }

.kv-hidden {
  display: none !important; }

/* ============================ */
/* VARIABLES                    */
/* ============================ */
/* ============================ */
/* SPINNER GENERAL              */
/* ============================ */
.spinner, .spinner:before, .spinner:after {
  width: 4px;
  height: 20px;
  background-color: #00B285;
  border-radius: 2px; }

.spinner {
  display: inline-block;
  position: relative; }
  .spinner:before, .spinner:after {
    content: "";
    position: absolute;
    display: block;
    top: 0px; }
  .spinner:before {
    left: -6px; }
  .spinner:after {
    left: 6px; }

/* ============================ */
/* SPINNER BOUNCE BOTTOM        */
/* ============================ */
@keyframes bounce-bottom {
  0% {
    height: 5px;
    margin-top: 15px; }
  50% {
    height: 20px;
    margin-top: 0px; }
  100% {
    height: 5px;
    margin-top: 15px; } }

.spinner-bounce-bottom {
  animation: bounce-bottom 0.6s ease 0.1s infinite; }
  .spinner-bounce-bottom:before, .spinner-bounce-bottom:after {
    top: auto;
    bottom: 0px; }
  .spinner-bounce-bottom:before {
    animation: bounce-bottom 0.6s ease 0s infinite; }
  .spinner-bounce-bottom:after {
    animation: bounce-bottom 0.6s ease 0.2s infinite; }

/* ============================ */
/* SPINNER BOUNCE TOP        */
/* ============================ */
@keyframes bounce-top
  0%
    height: 5px
    margin-bottom: $bar-height - 5px
  50%
    height: $bar-height
    margin-bottom: 0px
  100%
    height: 5px
    margin-bottom: $bar-height - 5px
    
@mixin bounce-top($dur: 0.5s, $delay: 0s)
  animation: bounce-top $dur ease $delay infinite
    
.spinner-bounce-top
  @include bounce-top(0.6s, 0.1s)
  &:before
    @include bounce-top(0.6s, 0s)
  &:after
    @include bounce-top(0.6s, 0.2s)  

/* ============================ */
/* SPINNER BOUNCE MIDDLE        */
/* ============================ */

@keyframes bounce-middle
  0%
    height: 4px
    margin-top: $bar-height / 2 - 2px
    margin-bottom: $bar-height / 2 - 2px
  50%
    height: $bar-height
    margin-top: 0px
    margin-bottom: 0px
  100%
    height: 4px
    margin-top: $bar-height / 2 - 2px
    margin-bottom: $bar-height / 2 - 2px
    
@mixin bounce-middle($dur: 0.5s, $delay: 0s)
  animation: bounce-middle $dur ease $delay infinite
    
.spinner-bounce-middle
  @include bounce-middle(0.6s, 0.1s)
  &:before,
  &:after
    top: 50%
    transform: translateY(-1 * $bar-height / 2) translateZ(0)
  &:before
    @include bounce-middle(0.6s, 0s)
  &:after
    @include bounce-middle(0.6s, 0.2s)  
    
/* ============================ */
/* SPINNER BLINK                */
/* ============================ */

@keyframes glow
  0%
    background-color: transparent
  50%
    background-color: $bar-color
  100%
    background-color: transparent

@mixin glow($dur: 0.5s, $delay: 0s)
  animation: glow $dur $delay infinite//
    
.spinner-blink
  @include glow(0.6s, 0.1s)
  &:before
    @include glow(0.6s, 0s)
  &:after
    @include glow(0.6s, 0.2s);
